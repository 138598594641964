@import url("https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff0d7 !important;
}

::-webkit-scrollbar {
  height: 6px;
  width: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
