.notfound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
}

.notfound-title {
  font-size: 24px !important;
  color: #671a19;
  text-align: center;
  padding-bottom: 12px;
  font-family: "livvic" !important;
  font-weight: 600 !important;
}
