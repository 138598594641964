.update-cancel-button {
  border-radius: 20px !important;
  border-color: #f9bb30e6 !important;
  border-width: 1px;
  color: #424242 !important;
}

.update-button {
  font-size: 14px;
  background-color: #f9bb30e6 !important;
  color: #424242 !important;
  border-radius: 20px !important;
}

.newStory-container {
  max-width: 1000px;
}
.newStory-title {
  font-size: 24px !important;
  color: #671a19;
  text-align: center;
  padding-bottom: 12px;
  font-family: "livvic" !important;
  font-weight: 700 !important;
}
