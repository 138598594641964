#consult-complaint-page {
  max-height: calc(100% - 60px);
  min-height: 350px;
  border-radius: 4px;
  overflow: auto;
  .semibold {
    font-weight: 600;
  }
}

@media screen and (max-width: 599px) {
  #consult-complaint-page {
    background-color: #fff0d7;
    width: 100svw;
  }
}
