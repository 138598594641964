.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTableHead-root th {
  color: var(--neutral-neutral-800, #424242) !important;
}
.MuiTable-root tr,
.MuiTable-root td {
  color: var(--neutral-neutral-800, #424242) !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}
.no-content-para {
  color: #000;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
