.card-container {
  background-color: none !important;
  border-radius: 8px;
  background-color: #fee5b6;
  color: black !important;
  padding: 30px;
  position: relative;
  width: 400px;
  max-width: 100%;
  text-align: center;
}

.card-container .round {
  border: 1px solid #781914;
  border-radius: 50%;
  padding: 7px;
}
