.forgotPassword-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 770px;
}
.forgotPassword-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.forgot-title {
  color: #671a19;
  text-align: center;
  width: 100%;
  padding-bottom: 14px;
  font-size: 24px !important;
  font-family: "livvic" !important;
  font-weight: 600 !important;
}
.forgotPassword-form-container {
  width: 320px;
  display: flex;
  flex-direction: column;
}
.forgotPassword-form {
  display: flex;
  gap: 14px;
  flex-direction: column;
}
.forgotPassword-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.forgotPassword-button {
  width: 100px;
  font-size: 14px;
  background-color: #f9bb30e6 !important;
  color: #424242 !important;
  border-radius: 20px !important;
}
.forgotPassword-forgot-text {
  color: #671a19;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: "livvic" !important;
}
