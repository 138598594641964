.communication-container {
  height: 100%;
  width: 600px;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .communication-container {
    background-color: #fff0d7;
    height: 100%;
    width: 100%;
  }
}
