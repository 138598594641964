.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  width: 100svw;
}

.loading-container svg {
  height: 200px;
  width: 200px;
  background-color: rgb(120, 25, 20);
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}
